import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../../../../ui-kit/themes/theme';
import { SummaryContent } from '../../../Content';
import * as COPY from '../../utilities/copy';
import { TotalSavingsContainer } from '../styles';

const propTypes = {
  handleSavings: PropTypes.func
};

export const TotalSavings = ({ handleSavings, optimizelyHierarchyFlip = false, isCartVariation }) => {
  const value = handleSavings();
  return (
    <>
      {!isCartVariation ? <SummaryContent.Divider /> : null}
      <TotalSavingsContainer isCartVariation={isCartVariation}>
        <SummaryContent.Item
          color={isCartVariation ? theme.colors.successGreen : theme.colors.deepForestGreen[100]}
          fontSize={optimizelyHierarchyFlip ? theme.fontSizes[7] + 'px' : ['14px', null, null, '14px']}
          fontWeight={theme.fontWeights.bold}
          lineHeight={optimizelyHierarchyFlip ? '24px' : '22px'}>
          <SummaryContent.Description data-testid="suite-total">{COPY.TOTAL_SAVINGS}</SummaryContent.Description>
          <SummaryContent.SubtotalAmount
            color={isCartVariation ? theme.colors.successGreen : theme.colors.deepForestGreen[100]}
            fontSize={optimizelyHierarchyFlip ? theme.fontSizes[7] + 'px' : ['14px', null, null, '14px']}
            fontWeight={theme.fontWeights.bold}
            lineHeight={optimizelyHierarchyFlip ? '24px' : '18px'}>
            {`${!isCartVariation && value > 0 ? '-' : ''}$${value.toFixed(2)}`}
          </SummaryContent.SubtotalAmount>
        </SummaryContent.Item>
      </TotalSavingsContainer>
    </>
  );
};

TotalSavings.propTypes = propTypes;
