import { useEffect, useRef } from 'react';
import {
  canCallEndpoint,
  clearEndpointTTLData,
  getLocationFromUrl,
  markEndpointAsCalled,
  setLocationFromUrl
} from '../../../store/componentStores/utilities/endpointTtlValidation';

const MAX_ENDPOINT_CALL_ATTEMPTS = 2;

const useEndpointValidation = (maxOccupancy, endpointList, resetCallback, resortLocation) => {
  const endpointCallAttemps = useRef(0);
  const url = window.location.href;

  //Removing the geolocation call when the user is on CA lodges
  //===============================================================================
  if (url.includes('southern-california') || url.includes('northern-california')) {
    endpointList = endpointList.filter(ep => ep.name !== 'geolocation');
  }

  useEffect(() => {
    const locationFromUrl = getLocationFromUrl();

    if (!url.includes(locationFromUrl)) {
      if (typeof resetCallback === 'function') {
        clearEndpointTTLData(endpointList);
        resetCallback();
        setLocationFromUrl();
      }
    }

    // Iterate over endpointList
    for (const endpoint of endpointList) {
      const { name, TTL, validateUrl, isLodgeSpecific } = endpoint;

      if (!isLodgeSpecific || resortLocation) {
        if (canCallEndpoint(name, TTL, validateUrl)) {
          // call validated endpoint
          if (typeof endpoint.call === 'function') {
            endpoint.call();
          }
          markEndpointAsCalled(name);
        }
      } else {
        // Left following log as commented code to assist in future debugging in case if LodgeConfig data is not
        // getting loaded properly during initial load of application
        // console.log('endpoint', name, 'is lodge specific but no resort location provided. Skipping call');
      }
    }
    endpointCallAttemps.current += 1;
    const shouldClearEndpointTTLData = !maxOccupancy && endpointCallAttemps.current < MAX_ENDPOINT_CALL_ATTEMPTS;
    if (shouldClearEndpointTTLData) clearEndpointTTLData(endpointList);
  }, [endpointList, resetCallback, resortLocation, url, maxOccupancy]);
};

export default useEndpointValidation;
