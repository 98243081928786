//DINING CREDIT SECTION TITLE
export const LEARN_MORE = 'Learn More';
export const BUY_DINING_CREDITS_NOW =
  'Buy dining credits now to save at all Great Wolf Lodge Restaurants during your stay.';

//DINING CREDIT LEARN MORE MODAL
export const INFO_TEXT =
  'Explore the restaurant menus in our app. You can Dine-In at the restaurants or mobile order through our app.';
export const RESTAURANTS = 'Restaurants Where You Can Use Your Dining Credits:';
export const INFO_LIST = [
  'Deal is only available for purchase before your stay',
  'Dining credits may be used anytime during your stay',
  'Credit is per room, per stay, and unused credit cannot be rolled over into a future stay'
];
export const DINING_CREDIT_PACKAGE_TITLE = `$<ALLOWANCE> dining credits for $<AMOUNT>`;

//DINING PAGE TITLE
export const DINING_TITLE = 'Save Later When You Buy Today!';
export const DINING_STAR_SUBTITLE = 'Only Available Before Your Arrival';
export const DINING_TITLE_UFF_FLOW = 'Take Advantage Of These Bonus Offers';
export const DINING_SUBTITLE_UFF_FLOW =
  'Add to your Ultimate Food & Fun Package with additional dining offers and credit deals.';

//DINING CREDIT CARD
export const SAVE_TAG_TEXT = 'Save $<SAVINGS>';
