//DAYPASS TOTALS
export const SUBTOTAL = 'Sub Total';
export const TAXES = 'Taxes';
export const TAXES_AND_FEES = 'Taxes and Fees';
export const PARKING_FEE = 'Parking Fee';
export const SUSTAINABILITY_FEE = 'Sustainability Fee';

//DAYPASS HEADER
export const NUMBER_OF_PASSES = '# of Day Passes';
export const VALID_ON = 'Valid On';
export const DAYPASS_SUMMARY = 'Day Pass Summary';

//DAYPASS FOOTER
export const TOTAL = 'Total';

//DAYPASS DETAILS
export const PRICE_PER_GUEST = 'Price per guest';
export const PROMOCODE_APPLIED = 'Code: <PROMOCODE> Applied';
export const DAYPASS_DETAILS = '<AMOUNT> <TITLE>';

//ADITIONAL CHARGES
export const ADDITIONAL_CHARGES = 'Additional Charges';

//PACKAGE LIST
export const REMOVE = 'Remove';

//DAYPASS TOTALS AND ADDITIONAL CHARGES SHARED COPIES
export const TOOLTIP_COPY =
  'The Day Pass Amenity Fee at Great Wolf Lodge encompasses a range of services and inclusions crafted to elevate family experiences and add extra conveniences. This fee covers amenities such as water park life jackets and towels, resort-wide access to wireless internet, and delightful complimentary Wolf Ears for children aged 12 and under.';
export const AMENITY_FEE = 'Amenity Fee';

//BIRTHDAY PARTY
export const PARTY_DATE = 'Party Date';
export const PARTY_TIME = 'Party Time';
export const PARTY_DETAILS = '<ADULTS> adults, <KIDS> kids, <INFANTS> infants, <SPECTATORS> spectators';
export const PARTY_DETAILS_EDIT = 'Edit Number of Guest';
export const PARTY_DUE_TODAY = 'Due today';
export const PARTY_DUE_BEFORE_PARTY = 'Due <DATE>';
export const PARTY_72_REFUND_PARTY_DISCLAIMER = '*Deposits are fully refundable within the first 72 hours of booking';
export const PARTY_REFUND_DISCLAIMER = '*A lodge member will reach out to help complete payment* ';
export const PARTY_NON_REFUND_DISCLAIMER = '*Booking less than 7 days before, this deposit is non-refundable*';
export const PARTY_SUMMARY = 'Party Summary';

//ARIA LABELS
export const PRICE_SECTION = 'Price Section';
export const PACKAGE_DESCRIPTION_SECTION = 'Package Description Section';
export const TOTAL_SECTION = 'Total Section';
export const DUE_TODAY_SECTION = 'Due Today Section';
export const DUE_BEFORE_PARTY_SECTION = 'Due Before Party Section';

export const SUMMARY_WATER_PARK_PASSES = 'Water Park Passes';
export const SUMMARY_INCLUDED = 'INCLUDED';
