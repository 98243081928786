import PropTypes from 'prop-types';
import React from 'react';
import { ImageBoxWrapper } from './assets/styles';

const propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string
};

const defaultProps = {
  src: '',
  alt: ''
};

const ImageBox = ({ src, alt, ...otherBoxProps }) => {
  return (
    <ImageBoxWrapper {...otherBoxProps}>
      <img src={src} alt={alt} />
    </ImageBoxWrapper>
  );
};

ImageBox.propTypes = propTypes;
ImageBox.defaultProps = defaultProps;

export default ImageBox;
