import React from 'react';
import { SummaryContent } from '../Content';
import { Divider } from '../Content/styles';
import SummaryAccordion from '../SummaryAccordion';
import { AdditionalCharges } from './Content/AdditionalCharges';
import { PackagesList } from './Content/PackageList';
import SimpleSummaryDue from './SimpleSummaryDue';
import SimpleSummaryFooter from './SimpleSummaryFooter';
import SimpleSummaryHeader from './SimpleSummaryHeader';
import SimpleSummaryInfo from './SimpleSummaryInfo';
import SimpleSummaryParkingFeeWarning from './SimpleSummaryParkingFeeWarning';

export const SimpleSummary = ({
  bookingSummaryParkingFee,
  operaCode,
  onChange,
  title,
  shouldStartCollapsed,
  deletePackage,
  total,
  productTitle,
  promoCode,
  fullWidthDescription,
  description,
  price,
  navigationText,
  onNavigateLinkClick,
  packagesList,
  taxes,
  showDueToday,
  dueToday,
  dueBeforeParty,
  sustainabilityFee,
  parkingFee,
  resortFee,
  leftTitle,
  leftContent,
  rightTitle,
  rightContent,
  onPaymentPage,
  includedList,
  remainingBalanceDueDate
}) => {
  return (
    <SummaryAccordion
      total={total}
      onChange={onChange}
      shouldStartCollapsed={shouldStartCollapsed}
      title={title}
      isOnPaymentPage={onPaymentPage}>
      {({ expanded, isDesktop }) => {
        return (
          <>
            {expanded ? (
              <SummaryContent>
                <SimpleSummaryHeader
                  leftTitle={leftTitle}
                  leftContent={leftContent}
                  rightTitle={rightTitle}
                  rightContent={rightContent}
                />
                <SimpleSummaryInfo
                  promoCode={promoCode}
                  fullWidthDescription={fullWidthDescription}
                  description={description}
                  productTitle={productTitle}
                  price={price}
                  navigationText={navigationText}
                  onNavigateLinkClick={onNavigateLinkClick}
                  includedList={includedList}
                />
                <Divider />
                <PackagesList currentSelectedPackages={packagesList} deletePackage={deletePackage} />

                <AdditionalCharges
                  taxes={taxes}
                  sustainabilityFee={sustainabilityFee}
                  parkingFee={parkingFee}
                  resortFee={resortFee}
                  lodgeOperaCode={operaCode}
                />
              </SummaryContent>
            ) : null}
            {expanded || isDesktop ? <SimpleSummaryFooter total={total} /> : null}
            {expanded && bookingSummaryParkingFee ? (
              <SimpleSummaryParkingFeeWarning bookingSummaryParkingFee={bookingSummaryParkingFee} />
            ) : null}
            {expanded && showDueToday ? (
              <SimpleSummaryDue
                dueToday={dueToday}
                dueBeforeParty={dueBeforeParty}
                remainingBalanceDueDate={remainingBalanceDueDate}
              />
            ) : null}
          </>
        );
      }}
    </SummaryAccordion>
  );
};

export default SimpleSummary;
