import { v4 as uuidv4 } from 'uuid';
import {
  COSTSUMMARY_TOOLTIP,
  PARKING_FEE,
  PRIVATE_LODGING_FEE,
  RESORT_FEE,
  SUSTAINABILITY_FEE,
  TAXES
} from './constants';

const getTaxesObject = (name, amount, tooltip) => {
  return {
    id: uuidv4(),
    name: name,
    amount: amount,
    tooltip: tooltip
  };
};

const FEE_WITH_TOOLTIP = {
  [SUSTAINABILITY_FEE]: COSTSUMMARY_TOOLTIP.sustainabilityFee,
  [RESORT_FEE]: COSTSUMMARY_TOOLTIP.resortFee
};

/**
 *
 * @param {number} sustainabilityFee Sustainability Fee
 * @param {number} taxesTotal Taxes Amount
 * @param {number} resortFee Resourt Amound
 * @param {number} parkingFee Parking Fee
 * @returns Array with the expected additional charges along with the tooltips
 */
export const getTaxesAndFees = (sustainabilityFee, taxesTotal, resortFee, parkingFee, privateLodgingFee) => {
  const taxesAndFees = [];

  if (sustainabilityFee > 0) {
    taxesAndFees.push(getTaxesObject(SUSTAINABILITY_FEE, sustainabilityFee, COSTSUMMARY_TOOLTIP.sustainabilityFee));
  }

  if (taxesTotal > 0) {
    taxesAndFees.push(getTaxesObject(TAXES, taxesTotal));
  }

  if (resortFee > 0) {
    taxesAndFees.push(getTaxesObject(RESORT_FEE, resortFee, COSTSUMMARY_TOOLTIP.resortFee));
  }

  if (parkingFee > 0) {
    taxesAndFees.push(getTaxesObject(PARKING_FEE, parkingFee));
  }

  if (privateLodgingFee > 0) {
    taxesAndFees.push(getTaxesObject(PRIVATE_LODGING_FEE, privateLodgingFee));
  }

  return taxesAndFees;
};

export const getTaxesAndFeesDynamic = (taxAndFeeLabelsDictionary, taxesAndFeesDetail, packagesTaxes = 0) => {
  const newTaxesAndFeesObject = Object.keys(taxAndFeeLabelsDictionary).map(key => {
    let amount = 0;
    if (taxAndFeeLabelsDictionary[key] === TAXES) {
      amount = taxesAndFeesDetail[key] + packagesTaxes;
    } else {
      amount = taxesAndFeesDetail[key] ?? 0;
    }
    return getTaxesObject(
      taxAndFeeLabelsDictionary[key],
      amount,
      FEE_WITH_TOOLTIP[taxAndFeeLabelsDictionary[key]] ?? false
    );
  });
  return newTaxesAndFeesObject.filter(fee => fee.amount !== 0);
};
