import * as Yup from 'yup';
import countries from '../utilities/countries';
import states from '../utilities/states';
import provinces from '../utilities/provinces';
import { onlyDigitsValidation, strLenIsBetweenRange } from '../utilities/validation/ValidationHelperMethods';
import { paymentFieldsLengths, errors } from './ValidationMessages';
import { specialRequestOptions } from './AdditionalRequests/selectOptions';
import { SUPPORTED_COUNTRIES, VALID_COUNTRIES } from '../../utilities/constants';
import { nonTraditionalTransactionTypes } from './PaymentFormHelper';

const createReservationSchema = (nonTraditionalTransactionType = null) => {
  let creditCardValues = {
    number: Yup.string().required(),
    cardCode: Yup.string().required()
  };

  const allDataIsRequired = nonTraditionalTransactionType === null;

  if (allDataIsRequired) {
    creditCardValues = {
      ...creditCardValues,
      cidNumber: Yup.string()
        .required()
        .test('is-number', errors.CVV_ONLY_NUMBERS, val => onlyDigitsValidation(val))
        .test('len', errors.CVV_LENGTH, val =>
          strLenIsBetweenRange(val, paymentFieldsLengths.CVV_MIN - 1, paymentFieldsLengths.CVV_MAX + 1)
        )
    };
  } else if (nonTraditionalTransactionType === nonTraditionalTransactionTypes.I4_NON_3DS) {
    creditCardValues = {
      ...creditCardValues,
      holder: Yup.string().required(),
      expiration: Yup.date().required()
    };
  }

  return Yup.object().shape({
    userId: Yup.string()
      .default(null)
      .notRequired()
      .nullable(),
    payment: Yup.object().shape({
      type: Yup.number()
        .positive()
        .integer()
        .required(),
      creditCard: Yup.object().when('type', {
        is: 1,
        then: Yup.object().shape(creditCardValues),
        otherwise: Yup.object().notRequired()
      }),
      affirm: Yup.object().when('type', {
        is: 3,
        then: Yup.object().shape({
          checkoutToken: Yup.string().required(),
          property: Yup.string().required(),
          amount: Yup.number().required(),
          resvId: Yup.string().nullable()
        }),
        otherwise: Yup.object().notRequired()
      })
    }),
    address: Yup.object().shape({
      street1: Yup.string().required(),
      city: Yup.string().required(),
      state: Yup.string()
        .when('country', {
          is: SUPPORTED_COUNTRIES.UnitedStates,
          then: Yup.string()
            .oneOf(states.map(state => state.abbreviation))
            .required()
        })
        .when('country', {
          is: SUPPORTED_COUNTRIES.Canada,
          then: Yup.string()
            .oneOf(provinces.map(province => province.abbreviation))
            .required()
        })
        .when('country', {
          is: val => !VALID_COUNTRIES.includes(val),
          then: Yup.string().notRequired()
        }),
      country: Yup.string()
        .oneOf(countries.map(country => country.code))
        .required(),
      postalCode: Yup.string()
        .when('country', {
          is: val => val === SUPPORTED_COUNTRIES.UnitedStates,
          then: Yup.string().length(5)
        })
        .required(),
      email: Yup.string()
        .email()
        .required()
    }),
    details: Yup.object().shape({
      comments: Yup.array()
        .of(Yup.string())
        .default(() => [])
        .notRequired()
        .nullable(),
      specialRequests: Yup.array()
        .of(
          Yup.object().shape({
            requestId: Yup.number().default(0),
            code: Yup.string().oneOf(specialRequestOptions.map(item => item.value))
          })
        )
        .notRequired(),
      sourceCode: Yup.string()
        .default('NA')
        .notRequired(),
      selection: Yup.object().shape({
        property: Yup.string()
          .uppercase()
          .required(),
        arrival: Yup.string().required(),
        departure: Yup.string().required(),
        roomCode: Yup.string().required(),
        adults: Yup.number()
          .integer()
          .required(),
        children: Yup.array()
          .of(Yup.number().integer())
          .default([]),
        offerCode: Yup.string()
          .uppercase()
          .notRequired(),
        rateCode: Yup.string()
          .uppercase()
          .notRequired(),
        site: Yup.string()
          .uppercase()
          .required(),
        suiteDescription: Yup.string().notRequired(),
        suiteTitle: Yup.string().notRequired(),
        checkoutTime: Yup.date().notRequired(),
        isDueBalanceBb: Yup.boolean()
          .default(true)
          .notRequired(),
        grandTotalDue: Yup.number().notRequired()
      }),
      items: Yup.array()
        .of(
          Yup.object().shape({
            quantity: Yup.number()
              .positive()
              .integer(),
            date: Yup.date().notRequired(),
            itemCode: Yup.string().notRequired(),
            rateCode: Yup.string()
              .uppercase()
              .notRequired(),
            total: Yup.number()
              .positive()
              .integer()
              .notRequired()
          })
        )
        .default([])
        .notRequired(),
      alerts: Yup.array()
        .of(
          Yup.object().shape({
            alert_code: Yup.string().notRequired(),
            alert_description: Yup.string().notRequired()
          })
        )
        .default([])
        .notRequired(),
      packages: Yup.array()
        .of(
          Yup.object().shape({
            description: Yup.string().notRequired(),
            shortDescription: Yup.string().notRequired(),
            packageCode: Yup.string().notRequired(),
            quantity: Yup.number()
              .positive()
              .integer()
              .notRequired(),
            tax: Yup.number().notRequired(),
            totalAmount: Yup.number().notRequired(),
            unitAmount: Yup.number().notRequired(),
            packageAmount: Yup.number().notRequired()
          })
        )
        .default([])
        .notRequired()
    }),
    qualifyingId: Yup.string().notRequired(),
    isCabana: Yup.boolean()
      .default(false)
      .notRequired(),
    cabanaSource: Yup.string().notRequired(),
    profileId: Yup.string()
      .notRequired()
      .nullable(),
    cabanaItem: Yup.object()
      .shape({
        quantity: Yup.number()
          .positive()
          .integer()
          .notRequired(),
        date: Yup.date().notRequired(),
        itemCode: Yup.string().notRequired(),
        rateCode: Yup.string()
          .uppercase()
          .notRequired(),
        total: Yup.number()
          .positive()
          .integer()
          .notRequired()
      })
      .default(null)
      .nullable()
      .notRequired(),
    customer: Yup.object().shape({
      gender: Yup.number()
        .oneOf([1, 2])
        .required(),
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      isCASLMarked: Yup.boolean().default(false)
    })
  });
};

export default createReservationSchema;
