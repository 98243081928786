// Offer reducer

import AuthClient from '../../../api/auth/AuthClient';

import {
  OFFERS_ACTIONS,
  offerEventBuilder
} from '../../../infrastructure/middleware/analytics/analyticsObjectBuilders';
import { pushEvent } from '../../../utilities/dataLayerUtils';
import { initializeState } from '../../../utilities/storageUtils';
import offerActionTypes from './offer.types';

const authClient = new AuthClient();

export const getDefaultOfferCode = (fallbackValue = '') => {
  const isUserLoggedIn = authClient.isLoggedIn();
  const promoCodeList = window.promoCodeList ? JSON.parse(window.promoCodeList) : [];
  const relatedOfferCode = promoCodeList.find(item => item.promoCode === window.dealPromoCode);
  const isGatedOffer = relatedOfferCode && JSON.parse(relatedOfferCode.isExclusive);

  if (isGatedOffer && !isUserLoggedIn) {
    return fallbackValue;
  }

  return window.dealPromoCode || fallbackValue;
};

const INITIAL_STATE = {
  code: getDefaultOfferCode(),
  description: '',
  validOffer: false,
  errorMessage: '',
  activeOffers: [],
  emailOffers: []
};

const offerReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  switch (action.type) {
    case offerActionTypes.ADD_OFFER_CODE:
      return { ...state, code: action.code };
    case offerActionTypes.SET_OFFER_CODE_DESCRIPTION:
      return {
        ...state,
        description: action.description,
        validOffer: action.description ? true : false,
        errorMessage: ''
      };
    case offerActionTypes.REMOVE_OFFER_CODE:
      return {
        ...state,
        code: ''
      };
    case offerActionTypes.CREATE_OFFER_ERROR:
      return {
        ...state,
        validOffer: false,
        errorMessage: action.errorMessage,
        description: ''
      };
    case offerActionTypes.ADD_OFFER_ACTIVE_TYPE: {
      const eventObject = offerEventBuilder(OFFERS_ACTIONS.ADD, {
        resortLocation: action.offerData.resortLocation,
        name: action.offerData.title,
        value: -action.offerData.dollarValue
      });
      pushEvent(eventObject);
      const activeOffers = [...state.activeOffers];
      if (!activeOffers.includes(action.offerData.id)) {
        activeOffers.push(action.offerData.id);
        return { ...state, activeOffers: activeOffers };
      }
      return state;
    }
    case offerActionTypes.ADD_OFFER_EMAIL_ACTIVE_TYPE: {
      const emailOffers = [...state.emailOffers];
      if (!emailOffers.includes(action.offerData.id)) {
        emailOffers.push(action.offerData.id);
        return { ...state, emailOffers: emailOffers };
      }
      return state;
    }
    case offerActionTypes.REMOVE_OFFER_ACTIVE_TYPE: {
      const eventObject = offerEventBuilder(OFFERS_ACTIONS.REMOVE, {
        resortLocation: action.offerData.resortLocation,
        name: action.offerData.title,
        value: -action.offerData.dollarValue
      });
      pushEvent(eventObject);
      const activeOffers = state.activeOffers.filter(offer => offer !== action.offerData.id);
      const activeEmailOffers = state.emailOffers.filter(offer => offer !== action.offerData.id);
      return { ...state, activeOffers: activeOffers, emailOffers: activeEmailOffers };
    }
    case offerActionTypes.REMOVE_OFFERS_ALL_ACTIVE_TYPE: {
      return { ...state, activeOffers: [], emailOffers: [] };
    }
    case offerActionTypes.VERIFY_ACTIVE_OFFERS: {
      const activeOffers = [...state.activeOffers];
      const currentUserOffers = action.offerData;
      const verifiedActiveOffer = activeOffers.filter(offerId =>
        currentUserOffers.some(offerObj => offerObj.id === offerId)
      );

      return { ...state, activeOffers: verifiedActiveOffer };
    }
    default:
      return state;
  }
};

export default offerReducer;
